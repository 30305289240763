import Link from "next/link";
import useLocalization from 'src/services/localization/useLocalization';
import { not_phone, phone } from "src/styles/media";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { sendRefProgramClick } from "../../helpers/analytics";
import MainPageWidget from "../MainPageWidget/MainPageWidget";

const ReferralProgramWidget = () => {
  const { fetchConfig: { language } } = useAppConfig();
  const localize = useLocalization();

  return (
    <Link
      href={`/${language}/account/discounts/`}
      data-marker='referral widget'
      onClick={() => sendRefProgramClick('main')}
    >
      <MainPageWidget
        iconSlot={<i className="icon-referral-percent ReferralProgramWidget__icon" />}
        mainSlot={<div className="ReferralProgramWidget">{localize('nav.discounts')}</div>}
      />
      <style jsx>{`
        @media ${not_phone} {
          .ReferralProgramWidget {
            max-width: 93px;
          }
        }
        .ReferralProgramWidget__icon {
         color: var(--colors-zakazGreen);
         font-size: 29px;
        }
        @media ${phone} {
          .ReferralProgramWidget__icon {
            font-size: 25px;
          }
        }
      `}</style>
    </Link>
  );
};

export default ReferralProgramWidget;
