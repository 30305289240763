import { Product } from "src/redux/apiTypes";
import { CartItem } from "src/redux/cart/general/cartTypes";
import { ProductWithAmount } from "src/redux/modals/types";

function mapProductsWithAmount(
  products: Product[],
  cartItems: CartItem[],
): ProductWithAmount[] {
  return products.map(product => {
    const cartItem = cartItems.find(i => i.ean === product.ean);

    return {
      ...product,
      amount: cartItem?.amount || 1,
    };
  });
}

export default mapProductsWithAmount;
