import requestPostProducts from 'src/ajax/requestPostProducts';
import { Product } from 'src/redux/apiTypes';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import useSWR from "swr";

export const irrelevantStatuses = ['delivered', 'cancelled'];

export interface PostProductsParams {
  storeId: string;
  eansList: string[];
  deliveryType?: string;
}

export const useSwrProductsByEans = (params: PostProductsParams) => {
  const { fetchConfig } = useAppConfig();
  const { eansList } = params;

  const requestParams = { ...params, fetchConfig };

  const { data, error } = useSWR(
    () => eansList.length > 0 ? [requestParams] : null,
    requestPostProducts,
    {
      dedupingInterval: 15000,
      refreshInterval: 300000,
      revalidateOnFocus: true,
    },
  );

  const lastViewProductsIsFetching = data === undefined && error === undefined;
  const lastViewProducts: Product[] = data || [];

  return {
    lastViewProducts,
    lastViewProductsIsFetching,
    error,
  };
};
