import { useState } from 'react';
import { Category, DeliveryType, RequestStatus } from 'src/redux/apiTypes';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { fetchPopularCategories } from './fetchPopularCategories';

export default function usePopularCategories() {
  const { fetchConfig } = useAppConfig();

  const [loadingStatus, setLoadingStatus] = useState<RequestStatus>('idle');
  const [popularCategories, setPopularCategories] = useState<Category[]>([]);

  const updatePopularCategories = async (storeId: string, deliveryType: DeliveryType) => {
    let res = {} as Response;

    try {
      setLoadingStatus('loading');
      res = await fetchPopularCategories({
        storeId,
        fetchConfig,
        deliveryType,
      });
    } catch (error) {
      setLoadingStatus('failed');
      console.error(error);
    }

    if (res.ok) {
      const data = await res.json();

      setLoadingStatus('succeeded');
      setPopularCategories(data);
    } else {
      setLoadingStatus('failed');
    }
  };

  return {
    updatePopularCategories,
    popularCategories,
    loadingStatus,
  };
}
