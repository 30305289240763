import ProductsSliderWithPlaceholder from 'src/components/views/ProductsSliderWithPlaceholder/ProductsSliderWithPlaceholder';
import useLastViewProductsProps from './useLastViewProductsProps';

interface LastViewProductsSliderProps {
  slideCount?: number;
  ean?: string;
}

export default function LastViewProductsSlider(props: LastViewProductsSliderProps) {
  const { slideCount = 5, ean } = props;

  const sliderProps = useLastViewProductsProps(slideCount, ean);

  return (
    <ProductsSliderWithPlaceholder
      placeholderTitle=''
      dataMarker='Last View Products'
      {...sliderProps}
    />
  );
}
