import { phone } from "src/styles/media";
import css from "styled-jsx/css";

export const styles = css`
  .SlotsWidget__icon {
    color: var(--uiColors-MainWidget-icon);
  }
  .SlotsWidget__icon_green {
    color: var(--colors-zakazGreen);
  }
  .SlotsWidget__text {
    max-width: 220px;
  }
  .SlotsWidget__text_accented {
    color: var(--uiColors-Button-accentedText);
  }

  @media ${phone} {
    .SlotsWidget__text {
      padding: 4px 0;
      line-height: 24px;
    }
  }
`;
