import { phone } from 'src/styles/media';
import typography from 'src/styles/typography';
import css from 'styled-jsx/css';

export const styles = css`
  .MainPageWidget {
    display: flex;
    padding: 16px;
    align-items: center;
    height: 80px;
    border-radius: var(--border-radius-block);
    background: var(--colors-white);
    cursor: pointer;
    width: 100%;
  }

  .MainPageWidget:hover {
    box-shadow: 0px 8px 15px 0px rgba(34, 36, 33, 0.08);
  }

  .MainPageWidget__icon {
    font-size: 27px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
  }
  .MainPageWidget__main {
    ${typography.h4};
    line-height: 1.5;
  }

  @media ${phone} {
    .MainPageWidget {
      padding: 12px 16px;
      height: auto;
    }
    .MainPageWidget__icon {
      width: 28px;
      height: 28px;
      font-size: 23px;
      margin-right: 12px;
    }
    .MainPageWidget__main {
      font-size: 14px;
    }
  }
`;
