import Link from "next/link";
import useLocalization from 'src/services/localization/useLocalization';
import { not_phone } from "src/styles/media";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { sendMyOrdersClick } from "../../helpers/analytics";
import MainPageWidget from "../MainPageWidget/MainPageWidget";

const OrdersWidget = () => {
  const { fetchConfig: { language } } = useAppConfig();
  const localize = useLocalization();

  return (
    <Link
      href={`/${language}/account/orders/`}
      data-marker='orders widget'
      onClick={() => sendMyOrdersClick('main')}
    >
      <MainPageWidget
        iconSlot={<i className="icon-bag-filled" style={{ color: 'var(--uiColors-MainWidget-icon)' }} />}
        mainSlot={<div className="OrdersWidget">{localize('nav.orders')}</div>}
      />
      <style jsx>{`
        @media ${not_phone} {
          .OrdersWidget {
            max-width: 70px;
          }
        }
      `}</style>
    </Link>
  );
};

export default OrdersWidget;
