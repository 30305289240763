import { useState } from 'react';
import useRequestStatus from 'src/hooks/useRequestStatus/useRequestStatus';
import { DeliveryType, Product } from 'src/redux/apiTypes';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { fetchSpecialProducts } from './helpers/fetchSpecialProducts';

export default function useSpecialProducts() {
  const { fetchConfig } = useAppConfig();

  const { status, setStatus } = useRequestStatus();
  const [specialProducts, setSpecialProducts] = useState<Product[]>([]);

  const updateSpecialProducts = async (
    storeId: string,
    deliveryType: DeliveryType,
  ): Promise<Product[]> => {
    let res = {} as Response;

    try {
      setStatus('loading');
      res = await fetchSpecialProducts({
        storeId,
        fetchConfig,
        deliveryType,
      });
    } catch (error) {
      setStatus('failed');
      console.error(error);
      return [];
    }

    if (res.ok) {
      const data: Product[] = await res.json();

      setStatus('succeeded');
      setSpecialProducts(data);
      return data;
    } else {
      setStatus('failed');
      return [];
    }
  };

  return {
    updateSpecialProducts,
    specialProducts,
    loadingStatus: status,
  };
}
