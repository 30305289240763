type AccountLinkPlace = 'main' | 'account_section';

export const sendRefProgramClick = (place: AccountLinkPlace) => {
  window.dataLayer.push({
    event: 'referral_program_button_click',
    place,
  });
};

export const sendMyOrdersClick = (place: AccountLinkPlace) => {
  window.dataLayer.push({
    event: 'my_orders_button_click',
    place,
  });
};

export const sendOffersClick = () => {
  window.dataLayer.push({
    event: 'store_promotions_button_click',
    place: 'main',
  });
};
