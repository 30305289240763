import { Fragment } from "react";
import { phone } from "src/styles/media";

export const ProductsIcon = <i className='icon-fresh' />;
export const WeightIcon = <i className='icon-weight' />;
export const PriceIcon = <i className='icon-fee' />;

export const OwnBrandIcon = <Fragment>
  <i className='OwnBrandIcon icon-own-brand' />

  <style jsx>{`
    .OwnBrandIcon {
      font-size: 20px;
    }

    @media ${phone} {
      .OwnBrandIcon {
        font-size: 16px;
      }
    }
  `}</style>
</Fragment>;

export const HaccpIcon = <Fragment>
  <i className='HaccpIcon icon-like'/>
  <style jsx>{`
    .HaccpIcon {
      font-size: 16px;
    }

    @media ${phone} {
      .HaccpIcon {
        font-size: 12px;
      }
    }
  `}</style>
</Fragment>;
