import { styles } from "./Benefits.styles";
import useBenefits from "./useBenefits";

const Benefits = () => {
  const benefits = useBenefits();

  return (
    <div className="Benefits" data-marker='Benefits'>
      {
        benefits.map(b => (
          <div
            key={b.label}
            className="Benefits__item"
            data-marker={b.dataMarker}
          >
            <div className="Benefits__iconWrapper">
              {b.icon}
            </div>
            <span className="Benefits__label">{b.label}</span>
          </div>
        ))
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default Benefits;
