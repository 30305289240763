import cn from 'classnames';
import ScheduleSelectInfoText from "src/components/views/ScheduleSelectInfoText/ScheduleSelectInfoText";
import useLocalization from 'src/services/localization/useLocalization';
import MainPageWidget from "../MainPageWidget/MainPageWidget";
import { styles } from "./SlotsWidget.styles";
import useSlotsWidgetProps from "./useSlotsWidgetProps";

const SlotsWidget = () => {
  const localize = useLocalization();
  const {
    handleClick,
    scheduleTextProps,
    withNewYearIcon,
    addressIsEmpty,
    textIsAccented,
    dataStatusLoading,
  } = useSlotsWidgetProps();

  return (
    <div data-status-loading={dataStatusLoading}> {/** don`t remove this div, its for e2e */}
      <div
        className="SlotsWidget"
        data-marker="Schedule"
        onClick={handleClick}
        role='button'
      >
        <MainPageWidget
          iconSlot={<i
            data-testid='icon'
            className={cn('SlotsWidget__icon', {
              'SlotsWidget__icon_green icon-christmas-tree': withNewYearIcon,
              'icon-clock': !withNewYearIcon,
            })}
          />}
          mainSlot={
            <div
              className={cn('SlotsWidget__text', {
                'SlotsWidget__text_accented': textIsAccented,
              })}
              data-testid='SlotsWidgetMain'
            >
              {
                addressIsEmpty
                  ? localize('select_address')
                  : <ScheduleSelectInfoText {...scheduleTextProps} />
              }
            </div>
          }
        />
        <style jsx>{styles}</style>
      </div>
    </div>
  );
};

export default SlotsWidget;
