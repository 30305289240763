import Head from 'next/head';
import { useDescription, useTitle } from './MainPageHeadHooks';

const MainPageHead = () => {
  const title = useTitle();
  const description = useDescription();

  return (
    <Head>
      <title key="title">{title}</title>
      <meta name="description" content={description} key="meta-description" />
    </Head>
  );
};

export default MainPageHead;
