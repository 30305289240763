import { DeliveryType, FetchConfig } from 'src/redux/apiTypes';

interface FetchPopularProductsParams {
  storeId: string;
  categoryId: string;
  deliveryType: DeliveryType;
  fetchConfig: FetchConfig;
}

export async function fetchPopularProducts(
  params: FetchPopularProductsParams,
): Promise<Response> {
  const { categoryId, storeId, deliveryType, fetchConfig } = params;
  const { xChain, xVersion, apiRoot, language } = fetchConfig;

  const query = [
    'page=1',
    'per_page=10',
    'available=true',
    'only_items=true',
    'sort=popularity_desc',
  ].join('&');

  const url = `${apiRoot}/stores/${storeId}/categories/${categoryId}/products/?${query}`;

  const options: RequestInit = {
    method: 'GET',
    headers: {
      'x-chain': xChain,
      'x-version': xVersion,
      'Accept-Language': language,
      'x-delivery-type': deliveryType,
    },
    credentials: 'include',
  };

  return await fetch(url, options);
}
