import { useState } from 'react';
import { DeliveryType, Product, RequestStatus } from 'src/redux/apiTypes';
import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { fetchPopularProducts } from './fetchPopularProducts';

export interface PopularProductsResponse {
  count: number;
  count_available: number;
  results: Product[];
  categories: null;
  filters: null;
  category_results: null;
  is_fuzzy: boolean;
}

export interface PopularProductsData {
  items: Product[];
  totalCount: number;
}

export interface UsePopularProductsParams {
  storeId: string;
  deliveryType: DeliveryType;
}

const initialData: PopularProductsData = {
  items: [],
  totalCount: 0,
};

export default function usePopularProducts(params: UsePopularProductsParams) {
  const { storeId, deliveryType } = params;
  const { fetchConfig } = useAppConfig();

  const [loadingStatus, setLoadingStatus] = useState<RequestStatus>('idle');
  const [popularProductsData, setPopularProductsData] = useState<PopularProductsData>(initialData);

  const updatePopularProducts = async (categoryId: string): Promise<PopularProductsData> => {
    let res = {} as Response;

    try {
      setLoadingStatus('loading');
      res = await fetchPopularProducts({
        storeId,
        categoryId,
        fetchConfig,
        deliveryType,
      });
    } catch (error) {
      setLoadingStatus('failed');
      console.error(error);
      return initialData;
    }

    if (res.ok) {
      const responseData: PopularProductsResponse = await res.json();
      const productsData: PopularProductsData = {
        items: responseData.results,
        totalCount: responseData.count,
      };

      setLoadingStatus('succeeded');
      setPopularProductsData(productsData);
      return productsData;
    } else {
      setLoadingStatus('failed');
      return initialData;
    }
  };

  return {
    updatePopularProducts,
    popularProductsData,
    loadingStatus,
  };
}
