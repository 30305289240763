import { Banner, FetchConfig } from 'src/redux/apiTypes';
import { getFetchBannersParams } from 'src/swr/useBanners/helpers/getFetchBannersParams';
import { fetchWithMemcached } from 'src/utils/ApiCacheClient/fetchWithMemcached';
import getPlaceByStoreId from './getPlaceByStoreId';

export default async function getBannersSsr(
  storeId: string,
  fetchConfig: FetchConfig,
): Promise<Banner[]> {
  const { apiRoot, xChain, xVersion, language } = fetchConfig;

  const { url, cacheKey } = getFetchBannersParams({
    place: getPlaceByStoreId(storeId),
    storeId,
    apiRoot,
  });

  const banners = await fetchWithMemcached<Banner[]>({
    url,
    cacheKey,
    fetchOptions: {
      headers: {
        'x-chain': xChain,
        'x-version': xVersion,
        'Accept-Language': language,
      },
    },
  });

  return banners.data;
}
