import { phone, phone_tablet, tablet } from "src/styles/media";
import typography from "src/styles/typography";
import css from "styled-jsx/css";

export const styles = css`
  .Benefits {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  .Benefits__item {
    display: flex;
    align-items: center;
  }

  .Benefits__label {
    ${typography.text}
    line-height: 18px;
  }

  .Benefits__iconWrapper {
    font-size: 18px;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    margin-right: 8px;
    color: var(--colors-brandLightGrey8);
  }

  @media ${phone_tablet} {
    .Benefits {
      margin-bottom: 24px;
    }
  }

  @media ${tablet} {
    .Benefits__item {
      max-width: 218px;
      align-items: flex-start;
    }
  }

  @media ${phone} {
    .Benefits {
      display: block;
    }

    .Benefits__item {
      margin-bottom: 8px;
    }

    .Benefits__iconWrapper {
      font-size: 14px;
      width: 16px;
      height: 16px;
      line-height: 16px;
    }

    .Benefits__label{
      font-size: 12px;
    }
  }
`;
