import { Fragment, useEffect } from 'react';
import selectDeliveryType from 'src/redux/cart/general/selectDeliveryType';
import { useAppSelector } from 'src/redux/hooks';
import useStoreService from 'src/services/storeManagement/useStoreService';
import PopularProductsSlider from '../PopularProductsSlider/PopularProductsSlider';
import usePopularCategories from './helpers/usePopularCategories';

export interface Props {
  place: string;
}

export default function PopularCategoriesSliders(props: Props) {
  const { place } = props;

  const { id: storeId } = useStoreService();

  const deliveryType = useAppSelector(selectDeliveryType);

  const {
    popularCategories,
    updatePopularCategories,
  } = usePopularCategories();

  useEffect(() => {
    updatePopularCategories(storeId, deliveryType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {popularCategories.map((category) => (
        <PopularProductsSlider
          categoryId={category.id}
          title={category.title}
          place={place}
          key={category.id}
        />
      ))}
    </Fragment>
  );
}
