import React, { ReactNode } from "react";
import { styles } from "./MainPageWidget.styles";

interface Props {
  iconSlot: ReactNode;
  mainSlot: ReactNode;
}


const MainPageWidget = (props: Props) => {
  const {
    mainSlot,
    iconSlot,
  } = props;

  return (
    <div className="MainPageWidget">
      <div className="MainPageWidget__icon">
        {iconSlot}
      </div>
      <div className="MainPageWidget__main">
        {mainSlot}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};

export default MainPageWidget;
