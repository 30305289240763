const networks = {
  ua: [
    'https://www.instagram.com/zakaz.ua/',
    'https://www.facebook.com/Zakaz.ua',
    'https://www.linkedin.com/company/zakaz-ua/',
  ],
  ru: [
    'https://www.facebook.com/Zakazru-102925437800145/',
    'https://vk.com/zakaz_rus',
    'https://www.instagram.com/zakaz.ru_/',
  ],
  md: [
    'https://www.facebook.com/md.zakaz/',
    'https://www.instagram.com/md.zakaz_/',
  ],
};

export default networks;