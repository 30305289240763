import Link from "next/link";
import useLocalization from 'src/services/localization/useLocalization';
import { not_phone } from "src/styles/media";
import { useAppConfig } from "src/utils/appConfig/useAppConfig";
import { sendOffersClick } from "../../helpers/analytics";
import MainPageWidget from "../MainPageWidget/MainPageWidget";

const OffersWidget = () => {
  const { fetchConfig: { language } } = useAppConfig();
  const localize = useLocalization();

  return (
    <Link
      href={`/${language}/offers/`}
      data-marker='offers widget'
      onClick={sendOffersClick}
    >
      <MainPageWidget
        iconSlot={<i className="icon-promo" style={{ color: 'var(--colors-red)' }} />}
        mainSlot={<div className="OffersWidget">{localize('store_offers')}</div>}
      />
      <style jsx>{`
        @media ${not_phone} {
          .OffersWidget {
            max-width: 68px;
          }
        }
      `}</style>
    </Link>
  );
};

export default OffersWidget;
