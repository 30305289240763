function getSearchWindowMicroMarking (baseURL: string) {
  return {
    "@context": "https://schema.org",
    "@type": "WebSite",
    "url": baseURL,
    "potentialAction": {
      "@type": "SearchAction",
      "target": `${ baseURL }/search/?q={search_term_string}`,
      "query-input": "required name=search_term_string",
    },
  };
}

export default getSearchWindowMicroMarking;
