import { phone, phone_tablet, tablet } from 'src/styles/media';
import css from 'styled-jsx/css';

export const styles = css`
  .MainPageWidgetsPanel {
    display: flex;
    margin-bottom: 40px;
  }
  .MainPageWidgetsPanel__item {
    margin-right: 16px;
    flex: 1 1 216px;
  }
  .MainPageWidgetsPanel__item_wide {
    flex-basis: 362px;
  }
  .MainPageWidgetsPanel__item:last-child {
    margin-right: 0;
  }

  @media ${phone_tablet} {
    .MainPageWidgetsPanel {
      flex-wrap: wrap
    }
  }

  @media ${tablet} {
    .MainPageWidgetsPanel__item {
      flex-basis: 31%;
      margin-bottom: 16px;
    }

    .MainPageWidgetsPanel__item_wide {
      flex-basis: 100%;
      margin-right: 0;
    }
  }

  @media ${phone} {
    .MainPageWidgetsPanel {
      margin-bottom: 16px;
    }
    .MainPageWidgetsPanel__item {
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`;
