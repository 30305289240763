import Cookies from 'js-cookie';
import { useMemo } from 'react';

const MAX_LENGTH_EANS = 20;
const LAST_VIEW_COOKIES_NAME = 'last_view_eans';

export default function useLastViewProductEansFromCookies() {
  const cookieValue = Cookies.get(LAST_VIEW_COOKIES_NAME);
  const eans: string[] = useMemo(() => cookieValue ? JSON.parse(cookieValue) : [], [cookieValue]);

  const addEanToCookies = (ean: string) => {
    const newEans = [ean, ...eans.filter(existingEan => existingEan !== ean)];
    const trimmedEans = newEans.slice(0, MAX_LENGTH_EANS);

    Cookies.set(LAST_VIEW_COOKIES_NAME, JSON.stringify(trimmedEans));
  };

  return {
    eans,
    addEanToCookies,
  };
}
